
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Listing, Profile } from 'client-website-ts-library/types';
import {
  ServiceManager,
  ServiceType,
} from 'client-website-ts-library/services';
import {
  AnalyticsEvent,
  AnalyticsEventObject,
  AnalyticsEventType,
  IAnalyticsAdapter,
  AnalyticsEventPriority,
} from 'client-website-ts-library/services/Analytics';

@Component
export default class ListingAgentProfile extends Vue {
  @Prop()
  public readonly listing!: Listing;

  @Prop({ default: false })
  public readonly firstOnly!: boolean;

  public get getAgents() {
    if (this.firstOnly) return this.listing.Agents.slice(0, 1);

    return this.listing.Agents;
  }

  private getShortAgentProfile(profile: Profile): string {
    const tmp = document.createElement('div');

    tmp.innerHTML = profile.AboutAgent;

    const text = tmp.innerText;

    return text.length < 120 ? text : `${tmp.innerText.substring(0, 100)}...`;
  }

  handlePhoneClicked(profile: Profile) {
    const analyticsService = ServiceManager.Get<IAnalyticsAdapter>(
      ServiceType.Analytics,
      'PROListAnalyticsAdapter',
    );

    const evt = new AnalyticsEvent(
      AnalyticsEventType.CallAgentClicked,
      AnalyticsEventObject.FromProfile(profile),
      AnalyticsEventPriority.Realtime,
    );

    analyticsService!.Record(evt);
  }
}
