


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { InspectionTime, Listing } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';

@Component
export default class InspectionCardV2 extends Vue {
  @Prop()
  public readonly inspectionTime!: InspectionTime;

  @Prop()
  private readonly listing!: Listing;

  public get dayOfMonthSuffix(): string {
    if (this.inspectionTime) {
      const dayOfMoth = this.inspectionTime.StartDateInfo.DayOfMonth;

      if (dayOfMoth > 3 && dayOfMoth < 21) return `${dayOfMoth}th`;
      switch (dayOfMoth % 10) {
        case 1:
          return `${dayOfMoth}st`;
        case 2:
          return `${dayOfMoth}nd`;
        case 3:
          return `${dayOfMoth}rd`;
        default:
          return `${dayOfMoth}th`;
      }
    }
    return '';
  }

  handleClicked() {
    API.Listings.GetOpenHomeICS(
      this.listing.Id,
      this.inspectionTime.StartDate,
      this.inspectionTime.EndDate,
    ).then((ics) => {
      const fileParts = [ics.VCalContent];
      const blob = new Blob(fileParts, { type: 'text/calendar' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);

      a.href = url;
      a.download = `${this.listing.Address.MicroAddress}.ics`;
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        a.remove();
      }, 10);
    });
  }
}
